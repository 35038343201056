import request from '@/plugin/axios'

// export function fetchList (query) {
//   return request({
//     url: '/admin/user/page',
//     method: 'get',
//     params: query
//   })
// }
/*
* 动态后台排序接口 *
*/
export function fetchList (query) {
  return request({
    url: '/admin/user/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/admin/user',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/admin/user/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/admin/user/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/admin/user',
    method: 'put',
    data: obj
  })
}
/*
*用户 判断重复 *
*/
export function getDetails (obj) {
  return request({
    url: '/admin/user/details/' + obj,
    method: 'get'
  })
}

export function getBranchUser (obj) {
  return request({
    url: '/admin/user/branchlist/' + obj,
    method: 'get'
  })
}

export function dingUserSync () {
  return request({
    url: '/admin/user/userSyc',
    method: 'get'
  })
}
export function alluser () {
  return request({
    url: '/admin/user/alluser',
    method: 'post'
  })
}
// 发送短信
export function sendSMS (mobile) {
  return request({
    url: '/admin/user/sendMobileCode/' + mobile,
    method: 'get'
  })
}
// 修改密码
export function resetPassword (obj) {
  return request({
    url: '/admin/user/resetPassWord',
    method: 'put',
    data: obj
  })
}
// 用户对应权限
export function getJurisdiction (obj) {
  return request({
    url: '/admin/user/userCorrespondingJurisdiction',
    method: 'post',
    data: obj
  })
}
/**
 * 根据部门ID查询部门所有人员信息
 */
export function getRegionPerson (obj) {
  return request({
    url: '/admin/user/accordingDeptInformation',
    method: 'post',
    params: { deptId: obj }
  })
}
/**
 * 查询运力部门所有在职人员信息
 */
export function getCapacityPerson () {
  return request({
    url: '/admin/user/selectCapacityDepartment',
    method: 'get'
  })
}
/**
 * 根据用户id 查询用户角色列表
 */
export function getUserRoleList (id) {
  return request({
    url: '/admin/role/getUserRoleList',
    method: 'get',
    params: { userId: id }
  })
}
/**
* 获取用户部门列表 *
*/
export function getUserDept (query) {
  return request({
    url: '/admin/user/getUserDept',
    method: 'post',
    data: query
  })
}
/**
 * 获取编辑用户部门日志列表 *
 */
export function getUserDeptLog (query) {
  return request({
    url: '/admin/user/getUserDeptLog',
    method: 'post',
    data: query
  })
}
/**
 * 用户管理根据项目名称获取部门信息 *
 */
export function getDeptByCustomer (query) {
  return request({
    url: '/admin/user/getDeptByCustomer',
    method: 'post',
    data: query
  })
}
// 用户管理新增用户部门
export function addUserDepts (obj) {
  return request({
    url: '/admin/user/addUserDepts',
    method: 'post',
    data: obj
  })
}
// 用户管理新增用户部门
export function delUserDepts (id) {
  return request({
    url: '/admin/user/delUserDepts/' + id,
    method: 'post'
  })
}
